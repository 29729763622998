import {
  InsightsBarDataItem,
  insightsContext,
  InsightsReachType,
} from '../../../../types/insights'
import {
  BarElementPath,
  BarPlot,
  ChartsAxisProps,
  ChartsLegend,
  ChartsXAxis,
  ChartsYAxis,
  LegendRendererProps,
  ResponsiveChartContainer,
} from '@mui/x-charts'
import { useTheme } from '@mui/material'
import { formatShortNumber } from '../../../../utils/eventFunctions'
import InsightsBarLabel from './InsightsBarLabel'
import InsightsBarLegend from './Legend/InsightsBarLegend'
import InsightsBarTooltip from './InsightsBarTooltip'
import { MakeOptional } from '@mui/x-charts/models/helpers'
import { AxisConfig, ScaleName } from '@mui/x-charts/models/axis'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'

interface Props {
  data: InsightsBarDataItem[]
  reachType: InsightsReachType | null
  orientation?: 'horizontal' | 'vertical'
}

const InsightsBarGraph = ({
  data,
  reachType,
  orientation = 'vertical',
}: Props) => {
  const { t } = useTranslation('common')
  const themeHook = useTheme()
  const { colors } = useContext(insightsContext)

  const ThemedLegend = (props: LegendRendererProps) => (
    <InsightsBarLegend
      {...props}
      position={{
        horizontal: 'right',
        vertical: 'top',
      }}
    />
  )

  const ThemedBar = (props) => (
    <BarElementPath
      {...props}
      sx={(theme) => ({
        '&:hover': {
          stroke: theme.palette.text.primary,
        },
      })}
    />
  )

  const highestValue = Math.max(...data.map((d) => d.count))

  const xAxis: MakeOptional<
    AxisConfig<ScaleName, any, ChartsAxisProps>,
    'id'
  > = {
    scaleType: 'band',
    data: [''],
  }
  const yAxis: MakeOptional<
    AxisConfig<ScaleName, any, ChartsAxisProps>,
    'id'
  > = {
    scaleType: 'linear',
    data: data.map((d) => d.count),
    max: Math.max(5, highestValue * (orientation === 'vertical' ? 1.25 : 1.1)), // Allow space for legend
  }

  return (
    <ResponsiveChartContainer
      resolveSizeBeforeRender={true}
      margin={
        orientation === 'vertical'
          ? {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }
          : {
              top: 0,
              bottom: 1,
              right: 0,
              left: 100,
            }
      }
      series={data.map((d) => ({
        id: d.type,
        type: 'bar',
        data: [d.count],
        label: t(`insightsModal.graphLabel_${d.type}`),
        layout: orientation,
      }))}
      // colors={[
      //   themeHook.palette.grey[800],
      //   themeHook.palette.grey[600],
      //   themeHook.palette.grey[400],
      //   themeHook.palette.grey[200],
      // ]}
      colors={colors}
      yAxis={[orientation === 'vertical' ? yAxis : xAxis]}
      xAxis={[orientation === 'vertical' ? xAxis : yAxis]}
    >
      <BarPlot
        barLabel={(item, context) => formatShortNumber(item.value)}
        slots={{
          barLabel: InsightsBarLabel,
          bar: ThemedBar,
        }}
        slotProps={{
          barLabel: {
            orientation: orientation,
          },
        }}
      />

      <ChartsXAxis
        disableTicks
        slotProps={{
          axisLine: {
            style: {
              strokeWidth: '1px',
              stroke: themeHook.palette.grey[600],
            },
          },
        }}
      />
      <ChartsYAxis
        disableTicks
        slotProps={{
          axisLine: {
            style: {
              strokeWidth: '1px',
              stroke: themeHook.palette.grey[600],
            },
          },
        }}
      />
      {orientation === 'vertical' && (
        <ChartsLegend
          direction='column'
          slots={{
            legend: ThemedLegend,
          }}
        />
      )}
      <InsightsBarTooltip reachType={reachType} />
    </ResponsiveChartContainer>
  )
}

export default InsightsBarGraph
