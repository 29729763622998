import React, { Dispatch, SetStateAction } from 'react'

export const insightsBreakpoint = 700

export enum InsightsPage {
  TICKETS = 'TICKETS',
  REACH = 'REACH',
  GUESTS = 'GUESTS',
  CHECK_INS = 'CHECK_INS',
}

export enum InsightHighlightType {
  TICKETS_SOLD = 'TICKETS_SOLD',
  REVENUE = 'REVENUE',
  REACHED_BY_HOST = 'REACHED_BY_HOST',
  REACHED_THROUGH_FRIENDS = 'REACHED_THROUGH_FRIENDS',
  EXTRA_REVENUE = 'EXTRA_REVENUE',
  RETURNING = 'RETURNING',
  BROUGHT_BY_FRIENDS = 'BROUGHT_BY_FRIENDS',
  FOLLOWERS = 'FOLLOWERS',
  CHECK_INS = 'CHECK_INS',
  BUSIEST = 'BUSIEST',
}

export interface InsightHighlight {
  type: InsightHighlightType
  value: number | null
}

export interface InsightsTotalBreakdownItem {
  type: string
  value: number
}

export interface InsightsTicketsItem {
  timestamp: string
  value: number
}

export interface InsightsCheckInsItem {
  timestamp: string
  value: number
}

export interface InsightsTicketsSpecialItem {
  type: 'UPDATE'
  timestamp: string
  value: number
}

export interface InsightsTicketsResult {
  tickets: InsightsTicketsItem[]
  items: InsightsTicketsSpecialItem[]
}

export interface InsightsCheckInsResult {
  check_ins: InsightsCheckInsItem[]
  // items: InsightsTicketsSpecialItem[]
}

export interface InsightsPageResult {
  total: number
  highlights: InsightHighlight[]
}

export interface InsightsResult {
  [InsightsPage.TICKETS]: InsightsPageResult
  [InsightsPage.REACH]: InsightsPageResult
  [InsightsPage.GUESTS]: InsightsPageResult
  [InsightsPage.CHECK_INS]: InsightsPageResult
}

export enum InsightsBarDataItemType {
  INVITES_SENT = 'INVITES_SENT',
  INVITES_OPENED = 'INVITES_OPENED',
  TICKETS = 'TICKETS',
  VIEWS = 'VIEWS',
  IMPRESSIONS = 'IMPRESSIONS',
}

export interface InsightsBarDataItem {
  count: number
  type: string
  items: InsightsTotalBreakdownItem[]
}

export enum InsightsPieDataItemType {
  SECOND_TIMERS = 'SECOND_TIMERS',
  NEWCOMERS = 'NEWCOMERS',
  REGULARS = 'REGULARS',
  FOLLOWING = 'FOLLOWING',
  SHARING = 'SHARING',
  COMMENTING = 'COMMENTING',
}

export interface InsightsPieDataItem {
  type: InsightsPieDataItemType
  promille: number
}

export enum InsightsGuestsType {
  VISITORS = 'VISITORS',
  ACTIONS = 'ACTIONS',
}

export interface InsightsGuestsResult {
  [InsightsGuestsType.VISITORS]: InsightsPieDataItem[]
  [InsightsGuestsType.ACTIONS]: InsightsBarDataItem[]
}

export enum InsightsReachType {
  HOST_INVITES = 'HOST_INVITES',
  HOST_REACH = 'HOST_REACH',
  SOCIAL_REACH = 'SOCIAL_REACH',
}

export interface InsightsReachResult {
  [InsightsReachType.HOST_INVITES]: InsightsBarDataItem[]
  [InsightsReachType.HOST_REACH]: InsightsBarDataItem[]
  [InsightsReachType.SOCIAL_REACH]: InsightsBarDataItem[]
}

export interface InsightsContextValue {
  page: InsightsPage
  setPage: Dispatch<SetStateAction<InsightsPage>>
  loading: boolean
  loadingReach: boolean
  loadingGuests: boolean
  result: InsightsResult | null
  guestsResult: InsightsGuestsResult | null
  reachResult: InsightsReachResult | null
  ticketsResult: InsightsTicketsResult | null
  checkInsResult: InsightsCheckInsResult | null
  colors: string[]
}

export const insightsContext = React.createContext<InsightsContextValue>({
  page: InsightsPage.TICKETS,
  setPage: () => {},
  loading: true,
  loadingReach: true,
  loadingGuests: true,
  result: null,
  guestsResult: null,
  reachResult: null,
  ticketsResult: null,
  checkInsResult: null,
  colors: [],
})
