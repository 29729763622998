import { BarLabelProps } from '@mui/x-charts'
import { useTheme } from '@mui/material'
import { useXScale, useYScale } from '@mui/x-charts/hooks'
import { useBarSeries } from '@mui/x-charts/hooks/useSeries'
import { fontFamily } from '../../../../shared-components/typography'

const InsightsBarLabel = ({
  orientation,
  seriesId,
  dataIndex,
  color,
  isFaded,
  isHighlighted,
  style,
}: BarLabelProps) => {
  const themeHook = useTheme()
  const series = useBarSeries()
  const yScale = useYScale()
  const xScale = useXScale()
  if (!series) return null

  const thisSeries = series.series[seriesId]
  const yValue = thisSeries.data[dataIndex] ?? 0

  const svgYPosition =
    orientation === 'vertical'
      ? (yScale(yValue) ?? 0) - 4
      : // @ts-expect-error y exists
        style.y.animation.to + 4

  const svgXPosition =
    orientation === 'vertical'
      ? // @ts-expect-error x exists
        style.x.animation.to
      : (xScale(yValue) ?? 0) + 12

  const svgPosition = {
    x: svgXPosition,
    y: svgYPosition,
  }

  const seriesLabelPosition = {
    x: 50,
    y: svgYPosition,
  }

  return (
    <>
      {orientation === 'horizontal' && (
        <text
          style={{
            transform: `translate(${seriesLabelPosition.x}px, ${seriesLabelPosition.y}px)`,
            textAnchor: 'middle',
            fontFamily: fontFamily,
            fontWeight: 500,
            fontSize: '0.5rem',
            lineHeight: '100%',
            letterSpacing: '0.2px',
            textAlign: 'center',
            fill: themeHook.palette.text.primary,
            transition: 'opacity 0.4s ease-in-out',
            opacity: svgXPosition && svgYPosition ? 1 : 0,
          }}
        >
          {typeof thisSeries.label === 'function'
            ? thisSeries.label('legend')
            : thisSeries.label}
        </text>
      )}

      <text
        style={{
          transform: `translate(${svgPosition.x}px, ${svgPosition.y}px)`,
          textAnchor: 'middle',
          fontFamily: fontFamily,
          fontWeight: 500,
          fontSize: '0.5rem',
          lineHeight: '100%',
          letterSpacing: '0.2px',
          textAlign: 'center',
          fill: themeHook.palette.text.primary,
          transition: 'opacity 0.4s ease-in-out',
          opacity: svgXPosition && svgYPosition ? 1 : 0,
        }}
      >
        {yValue}
      </text>
    </>
  )
}

export default InsightsBarLabel
