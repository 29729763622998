import Row from '../../../Row'
import Column from '../../../Column'
import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import AnimatedStateContent from '../../../AnimatedStateContent'
import { useContext, useRef, useState } from 'react'
import {
  insightsBreakpoint,
  insightsContext,
  InsightsGuestsType,
} from '../../../../types/insights'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import InsightsPieGraph from './InsightsPieGraph'
import InsightsBarGraph from '../Reach/InsightsBarGraph'
import { useTranslation } from 'react-i18next'

interface Props {
  sx?: SxProps<Theme>
}

const InsightsGuests = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  const containerRef = useRef(null)

  const [page, setPage] = useState<InsightsGuestsType>(
    InsightsGuestsType.VISITORS,
  )

  const { guestsResult } = useContext(insightsContext)

  const themeHook = useTheme()

  // In this case, using useMediaQuery is fine as this component is never shown on the first render of a page
  // If it was shown on the first render, you should always use breakpoints in css instead of in javascript
  const isMobile = useMediaQuery(themeHook.breakpoints.down(insightsBreakpoint))

  const onLeftClick = () => {
    setPage((prev) => {
      if (prev === InsightsGuestsType.VISITORS) return prev
      else return InsightsGuestsType.VISITORS
    })
  }

  const onRightClick = () => {
    setPage((prev) => {
      if (prev === InsightsGuestsType.VISITORS) {
        return InsightsGuestsType.ACTIONS
      } else return prev
    })
  }

  return (
    <Row
      sx={[
        {
          justifyContent: 'space-between',
          gap: 3,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Row
        sx={(theme) => ({
          position: 'relative',
          width: 1,
          gap: 3,
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          [theme.breakpoints.down(insightsBreakpoint)]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <AnimatedStateContent
          getContent={(page) => (
            <>
              {(isMobile
                ? [page]
                : [InsightsGuestsType.VISITORS, InsightsGuestsType.ACTIONS]
              ).map((i) => (
                <Column
                  key={i}
                  sx={{
                    gap: 2,
                    height: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    width: 1,
                  }}
                >
                  <Row
                    sx={(theme) => ({
                      width: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      [theme.breakpoints.down(insightsBreakpoint)]: {
                        justifyContent: 'space-between',
                      },
                    })}
                  >
                    <Typography variant='overline'>
                      {t(`insightsModal.graphTitle_${i}`)}
                    </Typography>

                    <Row
                      sx={(theme) => ({
                        gap: 0.75,
                        alignItems: 'center',
                        display: 'none',
                        [theme.breakpoints.down(insightsBreakpoint)]: {
                          display: 'flex',
                        },
                      })}
                    >
                      <ChevronLeft onClick={onLeftClick} />
                      <ChevronRight onClick={onRightClick} />
                    </Row>
                  </Row>
                  <Box
                    ref={containerRef}
                    sx={(theme) => ({
                      display: 'flex',
                      position: 'relative',
                      flexGrow: 1,
                      width: 1,
                      minHeight: 0,
                      minWidth: '100%',
                      [theme.breakpoints.down(insightsBreakpoint)]: {
                        bgcolor: 'background.paper',
                        p: 3,
                        borderRadius: 1,
                      },
                    })}
                  >
                    {containerRef.current && (
                      <>
                        {i === InsightsGuestsType.VISITORS && (
                          <InsightsPieGraph data={guestsResult![i]} />
                        )}
                        {i === InsightsGuestsType.ACTIONS && (
                          <InsightsBarGraph
                            orientation='horizontal'
                            data={guestsResult![i]} // TODO INSIGHTS: Shouldnt need to map anymore
                            reachType={null}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Column>
              ))}
            </>
          )}
          state={page}
          animateHeight={false}
        />
      </Row>
    </Row>
  )
}

export default InsightsGuests
