import React from 'react'

const ThickAdd = (props) => (
  <svg
    width='12'
    height='11'
    viewBox='0 0 12 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.697266 6.84961V4.35938H4.75V0.599609H7.24023V4.35938H11.3027V6.84961H7.24023V10.5996H4.75V6.84961H0.697266Z'
      fill='currentColor'
    />
  </svg>
)

export default ThickAdd
