import {
  insightsBreakpoint,
  insightsContext,
  InsightsPieDataItem,
} from '../../../../types/insights'
import { HighlightItemData, LegendRendererProps, PieChart } from '@mui/x-charts'
import { useMediaQuery, useTheme } from '@mui/material'
import InsightsBarLegend from '../Reach/Legend/InsightsBarLegend'
import { fontFamily } from '../../../../shared-components/typography'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  data: InsightsPieDataItem[]
}

const InsightsPieGraph = ({ data }: Props) => {
  const { t } = useTranslation('common')
  const themeHook = useTheme()
  const isMobile = useMediaQuery(themeHook.breakpoints.down(insightsBreakpoint))
  const [highlightedItem, setHighlightedItem] =
    useState<HighlightItemData | null>(null)

  const { colors } = useContext(insightsContext)

  const Legend = (props: LegendRendererProps) => (
    <InsightsBarLegend highlightedItem={highlightedItem} {...props} />
  )

  return (
    <PieChart
      highlightedItem={highlightedItem}
      onHighlightChange={setHighlightedItem}
      resolveSizeBeforeRender
      tooltip={{
        trigger: 'none',
      }}
      margin={{
        top: 0,
        right: 16,
        bottom: 0,
        left: 16,
      }}
      series={[
        {
          data: data.map((d) => ({
            id: d.type,
            value: d.promille,
            label: t(`insightsModal.graphLabel_${d.type}`),
          })),
          innerRadius: '65%',
          cy: isMobile ? undefined : '40%',
          paddingAngle: 0.5,
          highlightScope: { fade: 'global', highlight: 'item' },
          highlighted: {
            additionalRadius: 0,
          },
          faded: {
            additionalRadius: 0,
          },
        },
      ]}
      colors={colors}
      // colors={[
      //   themeHook.palette.grey[800],
      //   themeHook.palette.grey[600],
      //   themeHook.palette.grey[400],
      //   themeHook.palette.grey[200],
      // ]}
      slotProps={{
        legend: {
          position: {
            vertical: isMobile ? 'middle' : 'bottom',
            horizontal: 'middle',
          },
          labelStyle: {
            fontFamily: fontFamily,
            fontWeight: 400,
            fontSize: '0.5rem',
            lineHeight: '100%',
            letterSpacing: '0.2px',
            color: themeHook.palette.grey[800],
          },
        },
      }}
      slots={{
        legend: Legend,
      }}
      sx={(theme) => ({
        '& .MuiPieArc-highlighted': {
          stroke: theme.palette.primary.main,
        },
        '& .MuiPieArc-faded': {
          // opacity: 0.7,
          opacity: 1,
        },
      })}
    />
  )
}

export default InsightsPieGraph
