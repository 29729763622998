import * as React from 'react'
import { useContext } from 'react'
import NoSsr from '@mui/material/NoSsr'
import Popper from '@mui/material/Popper'
import { useItemTooltip, useMouseTracker } from '@mui/x-charts/ChartsTooltip'
import { useSvgRef, useXAxis } from '@mui/x-charts/hooks'
import { Typography } from '@mui/material'
import {
  generateVirtualElement,
  MousePosition,
} from '../generateVirtualElement'
import Column from '../../../Column'
import Row from '../../../Row'
import {
  InsightsBarDataItem,
  insightsContext,
  InsightsReachType,
} from '../../../../types/insights'

interface Props {
  reachType: InsightsReachType | null
}

const InsightsGraphTooltip = ({ reachType }: Props) => {
  const tooltipData = useItemTooltip()
  const mousePosition = useMouseTracker()
  const xAxis = useXAxis()
  const svgRef = useSvgRef()

  const { reachResult } = useContext(insightsContext)

  if (
    !tooltipData ||
    !mousePosition ||
    !xAxis.data ||
    !reachResult ||
    !reachType
  ) {
    // No data to display
    return null
  }

  const dataItem = reachResult[reachType].find(
    (i: InsightsBarDataItem) => i.type === tooltipData.identifier.seriesId,
  )

  if (svgRef.current === null || dataItem == null) {
    return null
  }

  const items = dataItem.items

  const tooltipPosition: MousePosition = {
    ...mousePosition,
  }

  if (!items || items.length === 0) return null

  return (
    <NoSsr>
      <Popper
        sx={{
          pointerEvents: 'none',
          zIndex: 10000,
        }}
        open
        placement={'top-start'}
        anchorEl={generateVirtualElement(tooltipPosition)}
      >
        <Column
          sx={{
            gap: 1.5,
            p: 1.5,
            bgcolor: 'background.paper',
            borderRadius: 0.75,
            border: `1px solid ${tooltipData.color}`,
          }}
        >
          <Typography
            variant='overline'
            sx={{
              fontSize: '0.5rem',
            }}
          >
            SOURCE
          </Typography>
          <Column
            sx={{
              gap: 1,
            }}
          >
            {items.map((i) => (
              <Row
                key={i.type}
                sx={{
                  width: 1,
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <Typography
                  variant='captionSmall'
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {i.type}
                </Typography>

                <Typography variant='captionSmall'>{`${(
                  (i.value / dataItem.count) *
                  100
                ).toFixed(0)}%`}</Typography>
              </Row>
            ))}
          </Column>
        </Column>
      </Popper>
    </NoSsr>
  )
}

export default InsightsGraphTooltip
