import { Box, Typography } from '@mui/material'
import Column from '../../Column'
import {
  InsightHighlight,
  InsightHighlightType,
  insightsBreakpoint,
} from '../../../types/insights'
import { useTranslation } from 'react-i18next'
import { useCurrentEvent } from '../../../utils/hooks'
import { EventDto } from '../../../types/event'
import { useSelector } from 'react-redux'
import { getCurrentMaxCapacity } from '../../../selectors/event'
import Row from '../../Row'
import ThickAdd from '../../../svg/ThickAdd'

interface Props {
  item: InsightHighlight
}

const InsightsHighlightItem = ({ item }: Props) => {
  const event: EventDto = useCurrentEvent()
  const { t } = useTranslation('common')
  const maxCapacity = useSelector(getCurrentMaxCapacity)

  const getMainText = () => {
    const firstCurrency = event.joinOptions?.[0]?.currency
    const formatter = new Intl.NumberFormat('en-EN', {
      style: 'currency',
      currency: firstCurrency ?? 'EUR',
    })

    const value = item.value

    switch (item.type) {
      case InsightHighlightType.REVENUE:
        return formatter.format((value ?? 0) / 100)
      case InsightHighlightType.EXTRA_REVENUE:
        return formatter.format((value ?? 0) / 100)
      case InsightHighlightType.BUSIEST:
        return value // TODO INSIGHTS: Correct formatting of dates
      default:
        return value
    }
  }

  const getExtraText = () => {
    switch (item.type) {
      case InsightHighlightType.TICKETS_SOLD:
        return maxCapacity ? `/ ${maxCapacity}` : null
      case InsightHighlightType.CHECK_INS:
        const percentage = ((item.value ?? 0) / maxCapacity) * 100
        return maxCapacity
          ? `/ ${maxCapacity} (${percentage.toFixed(0)}%)`
          : null
      case InsightHighlightType.FOLLOWERS:
        return null // TODO INSIGHTS: Add followers gained through this event
      default:
        return null
    }
  }

  const showPlus = [
    InsightHighlightType.REACHED_THROUGH_FRIENDS,
    InsightHighlightType.EXTRA_REVENUE,
  ].includes(item.type)

  return (
    <Column
      sx={(theme) => ({
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
        bgcolor: 'background.paper',
        borderRadius: 1.5,
        height: 86,
        px: 1,
        minWidth: 0,
        [theme.breakpoints.down(insightsBreakpoint)]: {
          height: 'auto',
          width: 1,
          flexDirection: 'row',
          p: 2,
          justifyContent: 'space-between',
        },
      })}
    >
      <Row
        sx={(theme) => ({
          alignItems: 'center',
          gap: 0.5,
          [theme.breakpoints.down(insightsBreakpoint)]: {
            textAlign: 'end',
            order: 1,
          },
        })}
      >
        {showPlus && (
          <Box
            sx={{
              color: 'text.secondary',
            }}
          >
            <ThickAdd />
          </Box>
        )}
        <Typography
          variant='h6'
          sx={(theme) => ({
            textAlign: 'center',
          })}
        >
          {getMainText()}
        </Typography>
        {getExtraText() != null && (
          <Typography
            variant='body2'
            sx={{
              color: 'text.secondary',
            }}
          >
            {getExtraText()}
          </Typography>
        )}
      </Row>
      <Typography
        variant='body2'
        sx={(theme) => ({
          textAlign: 'center',
          [theme.breakpoints.down(insightsBreakpoint)]: {
            textAlign: 'start',
          },
        })}
      >
        {t(`insightsModal.highlight_${item.type}`)}
      </Typography>
    </Column>
  )
}

export default InsightsHighlightItem
